@import "custom_font";
@import "../css/bootstrap.min.css";

body {
  font-family: 'Lato';
  a {
    color: #428bca;
    font-style: italic; }
  h1, h2, h3, h4, h5, h6 {
    font-family: 'Lato';
    font-weight: bold; }

  .footer {
    color: #ccc;
    font-weight: lighter;
    text-align: center;
    padding-bottom: 10px; }

  margin-top: 100px;

  .hello {
    font-size: 4em;
    font-weight: lighter; }

  .links {
    text-align: center;
    font-size: 10em;
    a:hover {
      text-decoration: none;
      color: black; } }

  @media (min-width: 992px) {
    .hello {
      font-size: 2em; }
    .links {
      font-size: 4em; } } }

